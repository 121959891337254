import { fetchAPI } from '../../../api/fetch';
import type { ProfileType } from '../../users/profileType';

interface SendMagicLinkProps {
  email: string;
  profileType: ProfileType;
  offer?: string;
  origin?: string;
}

export async function sendMagicLink({ email, profileType, offer, origin }: SendMagicLinkProps): Promise<void> {
  await fetchAPI('/auth/magic_login/get_link', {
    method: 'POST',
    body: JSON.stringify({
      destination: email,
      callback_origin: origin,
      profile_type: profileType,
      offer,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
}
